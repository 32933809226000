import React, { useRef, useState } from 'react';
import './imageTrack.css';

const ImageTrack = () => {
    const trackRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startPosition, setStartPosition] = useState(0);
    const [prevPercentage, setPrevPercentage] = useState(0);
    const [percentage, setPercentage] = useState(0);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartPosition(e.clientX);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;

        const mouseDelta = startPosition - e.clientX;
        const maxDelta = window.innerWidth;
        const newPercentage = (mouseDelta / maxDelta) * -100;
        let nextPercentage = prevPercentage + newPercentage;

        if (nextPercentage > 0) {
            nextPercentage = 0;
        }
        if (nextPercentage < -100) {
            nextPercentage = -100;
        }

        setPercentage(nextPercentage);

        requestAnimationFrame(() => {
            trackRef.current.style.transform = `translate(${nextPercentage}%, 0%)`;
            Array.from(trackRef.current.getElementsByClassName('image')).forEach(image => {
                image.style.objectPosition = `${nextPercentage + 100}% 50%`;
            });
        });
    };

    const handleMouseUp = () => {
        setIsDragging(false);
        setPrevPercentage(percentage);
    };

    return (
        <div 
            className="image-track-container"
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
        >
            <div 
                id="image-track"
                ref={trackRef}
            >
				<img src="/images/8th_Daytona_Shipping_Yard_color_.jpg" className="image" draggable="false" alt="Not Found"/>
				<img src="/images/Resized_5th_Daytona_Salt_Flats__1717026022731.jpg" className="image" draggable="false" alt="Not Found"/>
				<img src="/images/Resized_029A9797_1717026095552.jpg" className="image" draggable="false" alt="Not Found"/>
                <img src="/images/a24696eee565c7102d5d98ffd3ea40a3.jpg" className="image" draggable="false" alt="Not Found"/>
				<img src="/images/Resized_029A98701_1717026095008.jpg" className="image" draggable="false" alt="Not Found"/>
			</div>
		</div>
    );
};
 
export default ImageTrack; 