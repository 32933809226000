import './video.css';

const Video = () => {
    return ( 
       <div className="vidSection">
            <div className="videoPlayer">
            <iframe
                src='https://www.youtube.com/embed/ymJW3TT4yw4?autoplay=1'
                frameborder='0'
                allow='autoplay; encrypted-media'
                allowfullscreen
                title='video'
                width={window.innerWidth / 2}
                height={window.innerHeight / 2}
            />
            </div> 
            <div className="textContent">
                <h1 className="divTitle">The Collector</h1>
                <div className="vidDescription">
                    <h3>Description these are going to be placeholder words in order to get an idea of what this will look like when there is actual content. 
                        This collection is filled with a whole bunch of words and other things in order to really show how big it is.</h3>
                </div> 
            </div> 
       </div> 
     );
}
 
export default Video;
