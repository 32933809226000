import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import 'bootstrap/dist/css/bootstrap.min.css'
import './contact.css'

const Contact = () => {

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    const email = document.getElementsByName('user_email')
    const name = document.getElementsByName('user_name')
    const message = document.getElementsByName('message')
    if (name[0].value === '' || message[0].value === '' ||email[0].value === ''){
      alert("Please ensure that an email, name, and message are provided before submitting.")
      return;
    }

    emailjs.sendForm('service_28ax2c2', 'template_vz685ql', form.current, {
        publicKey: `${process.env.REACT_APP_API_KEY}`,
      })
      .then(
        () => {
          alert('Email sent successfully.');
          email[0].value = ''
          name[0].value = ''
          message[0].value = ''
        },
        (error) => {
          alert('FAILED...', error.text);
        },
      );
  };

  return (
    <div className="contactdiv">
      <form ref={form} onSubmit={sendEmail} id="contact">
        <div className="form-group">
          <label for="exampleFormControlInput1">Email address</label>
          <input type="email" name="user_email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com"></input>
        </div>
        <div className="form-group">
          <label for="exampleFormControlInput1">Name</label>
          <input type="text" name="user_name" className="form-control" id="exampleFormControlInput1"></input>
        </div>
        <div className="form-group">
          <label for="exampleFormControlTextarea1">Message</label>
          <textarea className="form-control" name="message" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>
        <div className='contactSubmit'>
          <button type="submit" className="btn btn-light">Submit</button>
        </div>
      </form>
    </div>
    
  );
};
 
export default Contact;