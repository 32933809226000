import ImageTrack from "./ImageTrack";
import Video from "./Video";
import ParaImages from "./ParaImages";
const Home = () => {
    return ( 
        <div className="home">
            <div className="trackDiv">
                <ImageTrack/>                
            </div>
            <div className="paraImages">
                <ParaImages/>
            </div>
            <div className="videoPlayer">
                <Video/>
            </div>
        </div>
     );
}
 
export default Home;