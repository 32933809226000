import React, { useEffect } from 'react';
import './paraImages.css';

const ParaImages = () => {
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      const images = document.querySelectorAll('.paraImage');
      const contents = document.querySelectorAll('.image-content');

      images.forEach((image) => {
        const newPositionY = 50 + scrollPosition * 0.01; // Adjust the multiplier for speed
        image.style.objectPosition = `0% ${newPositionY}%`;
      });

      contents.forEach((content) => {
        const contentTop = content.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (contentTop < windowHeight - 100) {
          content.classList.add('visible');
        } else {
          content.classList.remove('visible');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="parallax-container">
      <div className="image-wrapper">
        <img
          className="paraImage"
          src="/images/Resized_5th_Daytona_Salt_Flats__1717026022731.jpg"
          alt="Parallax 1"
        />
        <div className="image-content">
          <h1>Hemi Collection</h1>
          <button>Learn More</button>
        </div>
      </div>
      <div className="image-wrapper">
        <img
          className="paraImage"
          src="/images/a24696eee565c7102d5d98ffd3ea40a3.jpg"
          alt="Parallax 2"
        />
        <div className="image-content">
          <h1>For Sale</h1>
          <button>Learn More</button>
        </div>
      </div>
      <div className="image-wrapper">
        <img
          className="paraImage"
          src="/images/Resized_029A98701_1717026095008.jpg"
          alt="Parallax 3"
        />
        <div className="image-content">
          <h1>About the Collection</h1>
          <button>Learn More</button>
        </div>
      </div>
    </div>
  );
};

export default ParaImages;