import Navbar from './NavBar'; 
import Home from './Home';
import Footer from './Footer';
import Contact from './Contact';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <div className="content">
        <Home/>
        <Contact/>
      </div>
      <Footer/>    
    </div>
  );
}

export default App;
