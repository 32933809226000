import './navBar.css';
import 'bootstrap/dist/css/bootstrap.min.css'

const Navbar = () => {
    const title = 'The Riccio Collection';
    return ( 
        <nav className="navbar">
            <div className="links-left">
                <a href="/">View Collection</a>
                <a href="/">For Sale</a>
            </div>
            <div className="site-title-div">
                <a href="/" className="site-title">{title}</a>
            </div>
            <div className="links-right">
                <a href="#contact" role='button' class="btn btn-light">Contact</a>
            </div>
        </nav>
     );
}
 
export default Navbar;
