import './footer.css';

const Footer = () => {
    return ( 
        <div className="footer">
            <div className="links-left">
                <a href="/">View Collection</a>
                <a href="/">For Sale</a>
            </div>
            <div className="links-right">
                <a href="#contact">Contact</a>
            </div>
        </div>
     );
}
 
export default Footer;